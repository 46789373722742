// Tab Css
.atbd-tab{
    .nav-tabs{
        position: relative;
        margin-bottom: 18px;
        &:after{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            border-radius: 10px;
            content: "";
            @extend .bg-lighter;
        }
    }
    .nav-item{
        &:not(:last-child){
            margin-right: 12px;;
        }
    }
    .nav-link{
        position: relative;
        font-size: 14px;
        @extend .color-dark;
        &.active{
            &:after{
                opacity: 1;
                visibility: visible;
            }
        }
        &:after{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            border-radius: 10px;
            content: '';
            opacity: 0;
            visibility: hidden;
            z-index: 10;
            @extend .bg-primary;
        }
    }
    .tab-content{
        p{
            font-size: 14px;
        }
    }
}

// Tab Direction
.atbd-tab{
    &.tab-vertical{
        display: flex;
        max-height: 200px;
        overflow: hidden;
        .nav-tabs{
            flex: 1;
            flex-flow: column;
            margin: 0 20px 0 0;
            overflow-y: scroll;
            min-width: 80px;
            &:after{
                width: 2px;
                height: 100%;
                left: auto;
                right: 0;
            }
            .nav-item{
                margin-right: 0;
            }
            .nav-link{
                &:after{
                    width: 3px;
                    height: 100%;
                    left: auto;
                    right: 0;
                }
            }
        }
        .tab-content{
            flex: 19;
        }
    }
}
.tab-slide-content{
    .vertical-tabs{
        @include md{
            justify-content: center;
        }
    }
}

// Tab Size
.atbd-tab{
    &.tab-small{
        .nav-tabs{
            margin-bottom: 12px;
        }
        .nav-link{
            padding: 6px 12px;
        }
    }
    &.tab-large{
        .nav-tabs{
            margin-bottom: 25px;
        }
        .nav-link{
            padding: 15px 12px;
        }
    }
}

// Tan Nav Controller
.nav-controller-slide{
    .nav-controller-content{
        margin-top: 20px;
    }
}