/* file manager layout */
.fileM-contents {

    .fileM-grid-wrapper {
        padding: 30px;
        background-color: white;
        box-shadow: 0 5px 20px rgba($light, 0.03);
        border-radius: 10px;
    }

    @media (min-width: 1399px) {
        .col-20 {
            flex: 0 0 20.50%;
            max-width: 20.50%;
        }

        .col-79 {
            flex: 0 0 79.5%;
            max-width: 79.5%;
        }
    }
}

/* file manager sidebar */
.fileM-sidebar {
    height: 100vh;
    background: #fff;
    border-radius: 10px;

    .fileM-types {
        margin: 0px 0 63px 0;

        .sidebar__menu-group ul.sidebar_nav {
            margin: 21px 0 0 0;
            li.has-child >ul{
                visibility: hidden;
                opacity: 0;
            }
            li.has-child.open {
               > a{
                    @extend .color-primary;
                }
                >ul{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .sidebar__menu-group ul.sidebar_nav li.menu-title span {
            @extend .color-dark;
            @include ofs(16px, lh(16px, 20px), 500);
            padding: 0 14px;
        }

        .sidebar__menu-group ul.sidebar_nav li>a {
            padding: 0 5px 0 14px;
        }

        .sidebar__menu-group ul.sidebar_nav li>a .toggle-icon {
            position: absolute;
            left: -2px;
        }

        .sidebar__menu-group ul.sidebar_nav li>a .nav-icon {
            margin-right: 10px;
        }

        .sidebar__menu-group ul.sidebar_nav li ul {
            padding: 0px 0px 0px 25px;
        }

        .sidebar__menu-group ul.sidebar_nav li>a .nav-icon {
            width: 14px;
        }
    }

    ul {
        li a {
            color: $text-grey3;
            font-size: 14px;
            display: flex;
            align-items: center;
            min-height: 38px;
            padding: 0 20px;
            border-radius: 4px;
            transition: $transition-base;

            svg {
                color: $extra-light;
                width: 18px;
                margin-right: 13px;
            }

            &.active {
                color: $primary;

                svg {
                    color: $primary;
                }
            }

            &:hover {
                color: $primary;
            }
        }
    }

}

// File Manager progress
.fileM-progress {
    margin: 0 15px;

    @include sm {
        margin: 0;
    }

    .user-group-progress-bar {
        .progress {
            height: 5px;
            border-radius: 100px;

            span {
                color: #5A5F7D;
                @include ofs(12px, lh(12px, 16px), 500);
            }

            .progress-bar {
                border-radius: 100px;
            }
        }

        p {
            color: #5A5F7D;
            @include ofs(14px, lh(14px, 20px), 500);
            margin-bottom: 3px;
        }

        span {
            color: #868EAE;
            @include ofs(12px, lh(12px, 20px), 400);
        }
    }
}

// File Manager card
.fileM-wrapper__title {
    @include ofs(16px, lh(16px, 20px), 500);
    @extend .color-dark;
    margin-bottom: 20px;
}

.fileM-top-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    @include sm {
        flex-flow: column;

        form {
            margin-bottom: 15px;
        }
    }

    form {
        border: 1px solid #E3E6EF;
        border-radius: 6px;
        padding: 0 20px;

        svg {
            color: #9299B8;
            width: 15px;
        }

        input {
            padding: 8px 15px;
            &::placeholder {
                color: #ADB4D2;
                @include ofs(14px, lh(14px, 25px), 400);
            }
        }
    }
}

.fileM-card {
    text-align: center;

    .card {
        background-color: #F4F5F7;
    }

    .fileM-excerpt {
        @include ofs(14px, lh(14px, 20px), 500);
        @extend .color-dark;
        margin-bottom: 0;
    }

    .fileM-img {
        margin-bottom: 18px;
    }

}


.fileM-action {
    .fileM-action__right {
        .dropdown {
            top: 9px;
            right: 12px;
        }
    }
}

.fileM-action__right {
    .dropdown {

        .dropdown-default,
        .dropdown-menu {
            padding: 18px 0;
            margin-right: 0;
            box-shadow: 0 5px 20px rgba(#9299B8, 0.20);
        }

        .dropdown-item {
            @include ofs(14px, lh(14px, 32px), 400);
            color: #5A5F7D;
            display: flex;
            align-items: center;
            padding: 1px 25px;

            &:hover {
                background-color: rgba($primary, 0.05);
                @extend .color-primary;

                svg {
                    @extend .color-primary;
                }
            }

            svg {
                color: #9299B8;
                width: 16px;
                margin-right: 12px;
            }
        }

        svg {
            color: #868EAE;
            width: 20px;
        }
    }
}

.fileM-sidebar .fileM-action__right .dropdown {
    >a {
        width: 100%;
        font-size: 14px;

        svg {
            width: 15px;
            color: white;
        }
    }

    .dropdown-menu {
        width: 100%;
    }
}


//table
.filleM-table {
    .projectDatatable-title {
        font-weight: 500;
        color: #5A5F7D;
    }

    .files-area__title span {
        line-height: lh(12px, 22px);
        margin-top: 2px;
        text-transform: uppercase;
    }

    .table thead tr th {
        border-top: 1px solid #F1F2F6;
        padding: 14px 10px;

        &:first-child {
            border-left: 1px solid #F1F2F6;
        }

        &:last-child {
            border-right: 1px solid #F1F2F6;
        }
    }

    .table th,
    .table td {
        white-space: nowrap;
        padding: 9px 10px;
        vertical-align: middle;
        border-top: none;

        &:first-child {
            padding-left: 38px;
        }
    }

    tbody tr {
        transition: $transition-base;

        &:hover {
            box-shadow: 0 15px 50px rgba(#9299B8, 0.20);
        }
    }
}


//modal

.fileM-Modal-overlay {
    .modal {
        background-color: rgba(#111217, .60);
    }

    .modal-header {
        border-bottom: none;
        padding-bottom: 30px;
    }

    .modal-footer {
        border-top: none;
        padding: 0 30px 30px 30px;

        button {
            height: 36px;
            border: none;
            @extend .content-center;
        }
    }

    .modal-content {
        border-radius: 10px;
        box-shadow: 0 5px 20px rgba(#9299B8, 0.03);
    }

    .modal-body {
        padding: 0 30px;

        input {
            height: 36px;
            border-radius: 4px;
            &::placeholder{
                @include ofs(14px, lh(14px,25px), 400);
                color: #9299B8;
            }
        }
    }

    .modal-title button svg {
        width: 20px;
        color: #9299B8;
    }

    .modal-footer>* {
        margin: 5px;
    }

}

.fileM-sidebar {
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 390px;
            margin: 1.75rem auto;
        }
    }
}