//social box
.social-box{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    color: $white;
    font-size: 22px;
}