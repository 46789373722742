// Drag-drop List
.drag-drop-wrap {
  margin: 20px 0;
  .item {
    min-width: 200px;
    svg,
    img,
    i {
      @extend .color-extra-light;
    }
  }
  .item-info {
    margin-left: 30px;
  }
  .item_title {
    h6 {
      margin-left: 12px;
      font-size: 14px;
      a {
        @extend .color-dark;
      }
    }
  }
  .drag-drop {
    table {
      tr {
        cursor: move;
      }
    }
  }
  .date {
    min-width: 150px;
  }
}
