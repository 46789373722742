// Skeleton Css
.atbd-skeleton{
    @include e("title"){
        width: 30%;
        height: 16px;
        background: $border-normal;
        margin-bottom: 15px;
    }
    @include e("list"){
        li{
            height: 16px;
            width: 100%;
            margin-bottom: 15px;
            &:last-child{
                width: 40%;
                margin-bottom: 0;
            }
        }
    }
}

.shimmer-effect{
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px; 
    display: inline-block;
    position: relative; 
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: shimmerEffect;
    animation-timing-function: linear;
}