//CountDown
.counting-area {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    flex-wrap: wrap;
    margin: -25px;
    margin-bottom: 40px;
    @include md(){
        margin-bottom: 12px;
    }

    .number {
        display: flex;
        flex-flow: column;
        @include ofs(42px, lh(42px, 38px), 600);
        @extend .color-dark;
        min-width: 50px;
        margin: 25px;
        @include xs(){
            margin: 20px;
        }

        .text {
            @include ofs(16px, lh(16px, 20px), 400);
            color:#868EAE;
            text-transform: capitalize;
            margin-top: 12px;
        }
    }
}

.coming-soon{
    padding: 120px 0;
    @include md(){
        padding: 60px 0;
    }
    @include sm(){
        padding: 50px 0;
    }
    h1{
        @include ofs(58px,lh(58px, 62px), 600);
    }
    .subtitle{
        @include ofs(17px,lh(17px, 28px), 400);
        color:#5A5F7D;
        max-width: 580px;
        margin: 30px auto 53px;
        @include md(){
            margin: 37px auto 42px;
        }
        @include ssm(){
            margin: 29px auto 42px;
        }
    }
    @include e(body){
        margin-top: 168px;
        margin-bottom:163px;
        padding: 0 30px;
        @include md(){
            margin: 97px 0;
        }
        @include sm(){
            margin: 34px 0;
        }
    }
    .form-inline .form-control{
        width: 320px;
        max-width: 100%;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #E3E6EF;
        @include ssm(){
            width: 100%;
        }
    }
}

.coming-social{
    ul li {
        a{
            width: 44px;
            height: 44px;
            @extend .content-center;
            color: white;
            font-size: 18px;
        }
        &:not(:last-child){
            margin-right: 15px;
        }
    }
    p{
        @include ofs(14px, lh(14px, 25px), 400);
        @extend .color-light;
        margin-bottom: 0;
        text-align: center;
        margin-top:28px;
    }
}

.subscribe-bar{
    @include xs(){
        width: 100%;
        button,
        .form-group{
            width: 100%;
        }
    }

    button{
        height:48px;
    }
}