/* Date Picker */
.daterangepicker {
  top: 150px !important;
  border: 0 none;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 10px 30px rgba(#8f8e9f, 0.2);
  @media (max-width: 400px){
    width: 240px;
  }
  &:before,
  &:after {
    display: none;
  }
  .ranges {
    display: block;
    width: auto;
    padding: 20px;
    ul {
      width: 100%;
      li {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
        padding: 10px 18px;
        border: 1px solid $border-light;
        border-radius: 4px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &.active {
          @extend .bg-primary;
        }
      }
    }
  }
  .calendar-table {
    table {
      border-collapse: separate !important;
      border-spacing: 5px 8px !important;
    }
    thead {
      th {
        &.prev {
          span {
            margin-left: 3px;
          }
        }
        &.next {
          span {
            margin-right: 3px;
          }
        }
        &.prev,
        &.next {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          &:hover {
            background: #f7f7fe;
            span {
              border-color: $primary !important;
            }
          }
        }
      }
    }
  }
  &.show-calendar {
    border-radius: 8px;
    margin-top: 0;
    // &:after{
    //     left: 170px !important;
    //     top: 0;
    //     width: 1px;
    //     height: 100%;
    //     content: "";
    //     z-index: 33;
    //     border: 0 none;
    //     background: $border-light;
    // }
    .calendar-title {
      display: none;
    }
    .calendar-table {
      table {
        border-collapse: separate !important;
        border-spacing: 0 2px !important;
      }
      td {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        line-height: 32px;
        border: 0 none;
        position: relative;
        z-index: 33;
      }
      thead {
        th {
          font-size: 14px;
          color: #272b41;
          font-weight: 500;
          padding: 4px 0;
        }
        tr {
          &:last-child {
            th {
              font-size: 12px;
              color: $body-color;
            }
          }
        }
      }
      tbody {
        td {
          &.today {
            position: relative;
            background-color: #fff;
            border-radius: 50% !important;
            &:after {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              content: "";
              border-radius: 50%;
              @extend .border-primary;
            }
            &.off {
              &:after {
                display: none;
              }
            }
          }
          &.active {
            color: #fff !important;
            border: 0 none !important;
            background-color: #fff !important;
            position: relative;
            &.off {
              background-color: #fff !important;
              color: #adb4d2 !important;
            }
            &.in-range {
              &:after {
                display: none;
              }
            }
          }
          &.available {
            font-size: 13px;
            font-weight: 500;
            color: #272b41;
            &.off {
              color: #adb4d2;
            }
            &.in-range {
              background: #f7f7fe;
              position: relative;
              z-index: 2;
              border-radius: 0% !important;
              &.active {
                border-radius: 50% !important;
              }
              &.today {
                border-radius: 50% !important;
              }
            }
            &.active {
              &.start-date,
              &.end-date {
                color: #fff !important;
                border: 0 none !important;
                background-color: #fff !important;
                position: relative;
                &:after,
                &:before {
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  content: "";
                  border-radius: 50%;
                  z-index: -1;
                  @extend .bg-primary;
                }
                &:before {
                  left: 12px;
                  background: #f7f7fe;
                }
                &.in-range {
                  &::before {
                    opacity: 0;
                  }
                }
              }
              &.end-date {
                &:after {
                  display: block;
                }
                &:before {
                  left: auto;
                  right: 12px;
                  background: #f7f7fe;
                }
                &.in-range {
                  &:before {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .drp-buttons {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-color: $border-light;
      .drp-selected {
        font-size: 13px;
        color: $body-color;
        font-family: "Inter", sans-serif;
      }
      .cancelBtn {
        color: #5a5f7d;
        border: 1px solid $border-light;
        border-radius: 5px;
      }
      .btn {
        font-size: 13px;
        padding: 0 14.5px;
        font-weight: 600;
      }
    }
    &.ltr {
      .drp-calendar {
        &.left {
          border: 0 none;
        }
      }
    }
  }
  &.single {
    .calendar-table {
      padding-right: 0 !important;
      thead {
        th {
          font-size: 12px;
          height: 32px;
          &.available {
            span {
              border-width: 0 1px 1px 0;
              border-color: $dark;
            }
          }
        }
        .month {
          font-size: 15px;
          font-weight: 500;
        }
      }
      tbody {
        td {
          font-size: 13px;
          font-weight: 600;
          height: 32px;
          border-radius: 6px;
          box-shadow: 0 1px 2px rgba(#8f8e9f, 0.25);
          margin-bottom: 8px;
          @extend .color-dark;
          &.off {
            color: #bdbccc;
          }
          &.today,
          &.active {
            color: #fff;
            @extend .bg-primary;
          }
        }
      }
    }
    .calander-title {
      display: block;
      padding: 22px 15px 15px;
      font-size: 14px;
      text-align: center;
      border-bottom: 1px solid $border-light;
    }
    .drp-calendar {
      max-width: 305px;
      &.left {
        padding: 0 20px 20px;
      }
    }
  }
  .ranges li.active {
    @extend .bg-primary;
    color: white;
  }
}
