/*
Template Name: Striking Dash
Template URI: 
Author: Aazztech
Author URI: 
Version: 1.1.0
NB: this file contains the list of components/scss partial along with the style.scss contents order.
*/

/**                              **
**       TABLE OF CONTENTS       **
**                               **
-----------------------------------*/
/*
  01.COMMON
    -- miixins
    -- utility-classes
    -- keyframes
    -- general

  02.ELEMENTS
    -- background
    -- date-ranger
    -- social
  03.COMPONENTS
    -- navbar
    -- dropdown
    -- sidebar
    -- badges
    -- buttons
    -- alert
    -- avatar
    -- breadcrumb
    -- switch
    -- card
    -- carousel
    -- checkbox
    -- collapse
    -- comments
    -- notification
    -- empty
    -- card-socials
    -- card-charts
    -- profile
    -- table
    -- pagination
    -- modal
    -- progressbar
    -- result
    -- users
    -- input
    -- form
    -- timeline
    -- list
    -- menu
    -- message
    -- tag
    -- datepicker
    -- rate
    -- steps
    -- statistics
    -- spins
    -- upload
    -- pageHeader
    -- skeleton
    -- radio
    -- timepicker
    -- slider
    -- drawer
    -- tabs
    -- select
    -- tooltip
    -- projects
    -- chat
    -- calendar
    -- shop
    -- cart
    -- checkout
    -- table
    -- add-product
    -- order
    -- dashboard-base
    -- icons
    -- search
    -- galleys
    -- 404
    -- app-ui
    -- faq
    -- pricing
    -- note
    -- coming-soon
    -- change-log
    -- banner
    -- Testimonial
    -- fileM
    -- Import
    -- footer
  04. PAGES
    -- social-dashboard
    -- buttons
    -- avatar
    -- badges
    -- card
    -- email
    -- maintenance
    -- map
  
  05. RESPONSIVE CSS
***************************/
