// Collapse Css

.atbd-collapse {
    border-radius: 5px;
    border: 1px solid #d9d9d9;

    .atbd-collapse-item {
        border-bottom: 1px solid #d9d9d9;

        &:not(:last-child) {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            .atbd-collapse-item__header {
                border-radius: 5px 0 0 5px;
            }
        }

        &:not(:first-child) {
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            .atbd-collapse-item__header {
                border-radius: 0 5px 5px 0;
            }
        }
        &:last-child{
            border-bottom: none;
        }

        @include e("header") {
            margin-bottom: -1px;
            border-bottom: 1px solid #d9d9d9;
            @extend .bg-normal;

            &.active {

                i,
                span {
                    &:before {
                        content: '\f107';
                    }
                }
            }

            .item-link {
                display: flex;
                align-items: center;
                padding: 15px 20px;

                i,
                span,
                svg,
                img {
                    font-size: 12px;
                    margin-right: 18px;
                    @extend .color-light;
                }
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-size: 14px;
                font-weight: 400;
                @extend .color-gray;
            }
        }

        @include e("body") {
            .collapse-body-text {
                padding: 15px 20px;

                p {
                    font-size: 14px;
                    margin-bottom: 0;
                    @extend .color-light;
                }
            }
        }
    }
}


// Collapse Nested

.atbd-collapse {
    .atbd-collapse-item {
        .callapse-nested-block {
            padding: 20px;

            .atbd-collapse-item {
                border: 1px solid #d9d9d9;
                border-radius: 5px;
            }
        }
    }
}


// Collapse Borderless

.atbd-collapse {
    &.atbd-collapse-borderless {
        border: none;
        .atbd-collapse-item {
            border: none;
        }
        .atbd-collapse-item__header{
            border: none;
        }
    }
}


// Collapse Custom Panel

.atbd-collapse {
    &.atbd-collapse-custom {
        border: none;
        .atbd-collapse-item {
            border-radius: 0px;
            border: none;

            &:not(:last-child) {
                margin-bottom: 20px;
            }

            @include e("header") {
                border-bottom: 0;
                border-radius: 3px !important;
                border: none;
            }

            @include e("body") {
                .collapse-body-text {
                    padding-top: 0;
                }
            }
        }
    }
}