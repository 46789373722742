// Vector Map
#world-map {
  height: 650px;
}
.jvectormap-container {
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 18px;
    bottom: 25px;
    right: 0;
    top: auto;
    left: auto;
    border: 1px solid $border-normal;
    @extend .bg-white;
    @extend .color-gray;
  }
  .jvectormap-zoomin {
    border-radius: 6px 6px 0 0;
  }
  .jvectormap-zoomout {
    bottom: 0px;
    border-top: 0 none;
    border-radius: 0 0 6px 6px;
  }
}

// Region Map
.regions-svg {
  #region-map,
  #s_region-map_T,
  #s_region-map_W,
  #s_region-map_M,
  #s_region-map_Y,
  #s_region-map_W {
    height: 100%;
    width: 100%;
  }
}
// Google Map

#leaflet-basic,
#leaflet-multiIcon,
#leaflet-customIcon,
#leaflet-cluster,
.google-map {
  height: 450px;
}
