/* social overview */
.social-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -22px 0;
  li {
    width: 33.33%;
    text-align: center;
    padding: 22px 0;
    .social-box {
      margin-bottom: 14px;
      &.gradientInstragram {
        background: #9c27b0;
        background: -moz-linear-gradient(
          top,
          #9c27b0 0%,
          #9c27b0 35%,
          #f44336 67%,
          #ffc107 100%
        );
        background: -webkit-linear-gradient(
          top,
          #9c27b0 0%,
          #9c27b0 35%,
          #f44336 67%,
          #ffc107 100%
        );
        background: linear-gradient(
          to bottom,
          #9c27b0 0%,
          #9c27b0 35%,
          #f44336 67%,
          #ffc107 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9c27b0', endColorstr='#ffc107',GradientType=0 );
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      color: $text-grey1;
      margin-bottom: 0;
    }
  }
}
